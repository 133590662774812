import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";

export const LOGIN = "login";
export const LOGOUT = "logout";
export const FORGOT_PASSWORD = "forgotPassword";
export const VERIFY_AUTH = "verifyAuth";

export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_USER = "setCurrentUser";
export const SET_ERROR = "setError";

const state = {
  errors: [],
  user: null,
  isAuthorized: false
};

const getters = {
  currentUser(user: any) {
    user = JwtService.getCurrentUser();
    return JSON.parse(user);
  },
  isAuthorized() {
    return JwtService.getToken() != undefined || JwtService.getToken() != null ? true : false;
  }
};

const actions = {
  [FORGOT_PASSWORD](
    context: {
      commit: (arg0: string, arg1?: any[]) => void;
    },
    credentials: any
  ) {
    return new Promise(resolve => {
      ApiService.post("forgot-password", credentials)
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
          resolve(data);
        })
        .catch(response => {
          context.commit(SET_ERROR, [response.message]);
        });
    });
  },
  [LOGIN](
    context: {
      commit: (arg0: string, arg1: any[]) => void;
    },
    credentials: any
  ) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(response => {
          context.commit(SET_ERROR, [response.message]);
        });
    });
  },
  [LOGOUT](context: { commit: (arg0: string) => void }) {
    context.commit(PURGE_AUTH);
  },
  [VERIFY_AUTH](context: any) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
    }
  },
  [SET_ERROR](
    context: {
      commit: (arg0: string, arg1: any) => void;
    },
    errors: any
  ) {
    context.commit(SET_ERROR, errors);
  }
};

const mutations = {
  [SET_ERROR](
    state: {
      errors: any;
    },
    error: any
  ) {
    state.errors = error;
  },
  [SET_AUTH](
    state: {
      user: any;
      errors: [];
      isAuthorized: any;
    },
    data: {
      user: any;
      token: {
        original: {
          token: string;
        };
      };
    }
  ) {
    state.user = data.user;
    state.errors = [];
    state.isAuthorized = true;
    JwtService.saveCurrentUser(JSON.stringify(data.user));
    JwtService.saveToken(data.user.token.original.token);
    ApiService.setHeader();
  },
  [SET_USER](user: any) {
    state.user = user;
  },
  [PURGE_AUTH](state: { user: {}; errors: []; isAuthorized: any }) {
    state.user = {};
    state.errors = [];
    state.isAuthorized = false;
    JwtService.destroyCurrentUser();
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
