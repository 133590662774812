const ID_TOKEN_KEY = "id_token";
const ID_CURRENT_USER = "id_current_user";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = (token: string) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getCurrentUser = () => {
  return window.localStorage.getItem(ID_CURRENT_USER);
};

export const saveCurrentUser = (user: string) => {
  window.localStorage.setItem(ID_CURRENT_USER, user);
};

export const destroyCurrentUser = () => {
  window.localStorage.removeItem(ID_CURRENT_USER);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getCurrentUser,
  saveCurrentUser,
  destroyCurrentUser
};
