import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import jwtService from "./services/jwt.service";
import { VERIFY_AUTH } from "@/store/auth.module";
import ApiService from "@/services/api.service";

//Sass
import "@/styles/main.scss";

import vuetify from "@/plugins/vuetify";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

ApiService.init();
ApiService.setHeader();

router.beforeEach((to, from, next:any) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
