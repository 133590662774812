<template>
  <div>
    <nav
      class="navbar navbar-expand nav-color top-menu d-block d-md-none px-0 py-2"
    >
      <div class="container">
        <b-navbar-brand to="/"
          ><img class="ml-4 mt-2" src="/images/logo/logo-64x64.png" alt="logo"
        /></b-navbar-brand>
      </div>
    </nav>
    <b-navbar
      toggleable="sm"
      type="dark"
      variant="dark"
      class="d-none d-xl-block"
    >
      <div class="container">
        <b-navbar-brand to="/"
          ><img src="/images/logo/logo-64x64.png" alt="logo"
        /></b-navbar-brand>

        <b-collapse id="nav-text-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item v-show="isAuthorized" href="/dashboard"
              >Dashboard</b-nav-item
            >
            <b-nav-item href="/">Home</b-nav-item>
            <b-nav-item href="/about">About</b-nav-item>
            <b-nav-item href="/contact">Contact</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
        <b-navbar-nav class="ml-auto" v-show="!isAuthorized">
          <b-link href="/login" class="btn btn-light px-3 py-1"
            ><i class="fas fa-sign-in-alt"></i> Login</b-link
          >
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto dropdown" v-show="isAuthorized">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-user-tie"></i> {{ currentUser.full_name }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="#" v-b-modal.profile_modal
              ><i class="fas fa-address-card"></i> Profile</a
            >
            <a class="dropdown-item" href="/login"
              ><i class="fas fa-sign-out-alt"></i> Logout</a
            >
          </div>
        </b-navbar-nav>
      </div>
    </b-navbar>
    <Slide right>
      <a v-show="isAuthorized" href="/dashboard">Dashboard</a>
      <a class="" href="/">Home</a>
      <a class="" href="/about">About</a>
      <a class="" href="/contact">Contact</a>
      <a class="" href="/login" v-show="!isAuthorized">Login</a>
      <a class="" href="/login" v-show="isAuthorized">Logout</a>
    </Slide>
  </div>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import { Slide } from "vue-burger-menu";
export default {
  components: {
    Slide
  },
  data() {
    return {
      isAuthorized: false,
      currentUser: {
        full_name: null
      }
    };
  },
  created() {
    this.isAuthorized = this.$store.getters.isAuthorized;
    if (this.$store.getters.currentUser != null)
      this.currentUser = this.$store.getters.currentUser;
  }
};
</script>
<style lang="scss">
nav {
  background-color: transparent !important;
  font-size: 12px;
  a {
    color: #000;
  }
}
.bm-burger-button {
  display: block;

  @media (min-width: 769px) {
    display: none;
  }
}
.btn-login {
  color: #fff !important;
  // background-color: transparent;
  border-color: #fff !important;

  &:hover {
    color: #000 !important;
    background-color: #fff;
    border-color: #fff;
  }
}
.bm-menu {
  background-color: #fdfdfd;
  color: #000 !important;
}
.nav-color {
  background-color: #fdfdfd !important;
}
</style>
