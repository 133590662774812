<template>
  <b-modal ref="userModal" id="user_modal" hide-footer hide-header scrollable>
    <div>
      <div class="d-flex justify-content-between align-items-center my-2">
        <h3 class="w-700">Create/Edit User Account</h3>
      </div>
      <hr />
      <form @submit.stop.prevent="onSubmit">
        <b-form-group id="lblFullName" label="" label-for="txtFullName">
          <b-form-input
            class="form-control col-12"
            id="txtFullName"
            size="sm"
            name="txtFullName"
            placeholder="Full Name"
            type="text"
            v-model="$v.form.full_name.$model"
            :state="validateState('full_name')"
            aria-describedby="full_name-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="full_name-live-feedback">
            Name is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="lblNIC" label="" label-for="txtNIC">
          <b-form-input
            class="form-control col-12"
            id="txtNIC"
            size="sm"
            name="txtNIC"
            placeholder="NIC"
            type="text"
            v-model="$v.form.nic.$model"
            :state="validateState('nic')"
            aria-describedby="nic-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="nic-live-feedback">
            NIC is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="lblUserName" label="" label-for="txtUserName">
          <b-form-input
            class="form-control col-12"
            id="txtUserName"
            size="sm"
            name="txtUserName"
            placeholder="Username"
            type="text"
            :disabled="!isCreated"
            v-model="$v.form.user_name.$model"
            :state="validateState('user_name')"
            aria-describedby="user_name-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="user_name-live-feedback">
            Username is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="lblEmail" label="" label-for="txtEmail">
          <b-form-input
            class="form-control col-12"
            id="txtEmail"
            size="sm"
            name="txtEmail"
            placeholder="Email"
            :disabled="!isCreated"
            type="text"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="email-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="email-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="lblRole"
          label="User Role"
          label-size="sm"
          label-for="drpRole"
        >
          <b-form-select
            v-model="$v.form.role_id.id.$model"
            :options="roles"
            id="drpRole"
            name="drpRole"
            size="sm"
            value-field="id"
            text-field="role_name"
          ></b-form-select>
          <b-form-invalid-feedback id="role_id-live-feedback">
            Role is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="lblPermanentAddress"
          label=""
          label-for="txtPermanentAddress"
        >
          <b-form-textarea
            class="form-control col-12"
            id="txtPermanentAddress"
            size="sm"
            name="txtPermanentAddress"
            placeholder="Permanent Address"
            rows="5"
            type="text"
            v-model="$v.form.permanent_address.$model"
            :state="validateState('permanent_address')"
            aria-describedby="permanent_address-live-feedback"
          ></b-form-textarea>
          <b-form-invalid-feedback id="permanent_address-live-feedback">
            Address is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="lblResidentialAddress"
          label=""
          label-for="txtResidentialAddress"
        >
          <b-form-textarea
            class="form-control col-12"
            id="txtResidentialAddress"
            size="sm"
            name="txtResidentialAddress"
            placeholder="Residential Address"
            rows="5"
            type="text"
            v-model="form.residential_address"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group id="lblMobile" label="" label-for="txtMobile">
          <b-form-input
            class="form-control col-12"
            id="txtMobile"
            size="sm"
            name="txtMobile"
            placeholder="Mobile"
            type="text"
            v-model="$v.form.mobile.$model"
            :state="validateState('mobile')"
            aria-describedby="mobile-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="mobile-live-feedback">
            Mobile is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="lblPhone" label="" label-for="txtPhone">
          <b-form-input
            class="form-control col-12"
            id="txtPhone"
            size="sm"
            name="txtPhone"
            placeholder="Phone"
            type="text"
            v-model="$v.form.phone.$model"
            :state="validateState('phone')"
            aria-describedby="phone-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="phone-live-feedback">
            Phone is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="lblReferee1" label="" label-for="txtReferee1">
          <b-form-textarea
            class="form-control col-12"
            id="txtReferee1"
            size="sm"
            name="txtReferee1"
            placeholder="Referee (1)"
            rows="5"
            type="text"
            v-model="$v.form.referee1.$model"
            :state="validateState('referee1')"
            aria-describedby="referee1-live-feedback"
          ></b-form-textarea>
          <b-form-invalid-feedback id="referee1-live-feedback">
            Referee is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="lblReferee2" label="" label-for="txtReferee2">
          <b-form-textarea
            class="form-control col-12"
            id="txtReferee2"
            size="sm"
            name="txtReferee2"
            placeholder="Referee (2)"
            rows="5"
            type="text"
            v-model="$v.form.referee2.$model"
            :state="validateState('referee2')"
            aria-describedby="referee2-live-feedback"
          ></b-form-textarea>
          <b-form-invalid-feedback id="referee2-live-feedback">
            Referee is required.
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
    </div>
    <b-button size="sm" variant="outline-danger" class="mr-1" @click="resetForm"
      >RESET</b-button
    >
    <b-button
      type="submit"
      size="sm"
      variant="outline-primary"
      @click="onSubmit"
      >SUBMIT</b-button
    >
    <div class="row mt-2" v-show="form.loans.length > 0">
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <th>Loan ID</th>
              <th>Loan Date</th>
              <th>Amount</th>
              <!-- <th>Status</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in form.loans" :key="index">
              <td>{{ item.reference_code }}</td>
              <td>
                {{ new Date(item.created_at).toISOString().slice(0, 10) }}
              </td>
              <td>{{ item.amount }}</td>
              <!-- <td>{{ item.status }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import ApiService from "@/services/api.service";
import Swal from "sweetalert2";
export default {
  mixins: [validationMixin],
  name: "user-form",
  data() {
    return {
      isCreated: true,
      form: {
        id: 0,
        account_id: null,
        status: 1,
        full_name: null,
        nic: null,
        email: null,
        permanent_address: null,
        residential_address: null,
        mobile: null,
        phone: null,
        user_name: null,
        referee1: null,
        referee2: null,
        role_id: {
          id: 4
        },
        loans: []
      },
      roles: []
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      full_name: {
        required
      },
      nic: {
        required
      },
      permanent_address: {
        required
      },
      mobile: {
        required
      },
      phone: {
        required
      },
      user_name: {
        required
      },
      referee1: {
        required
      },
      referee2: {
        required
      },
      role_id: {
        id: {
          required
        }
      }
    }
  },
  created() {
    ApiService.get("active-roles").then(({ data }) => {
      this.roles = data.roles;
    });
  },
  methods: {
    getIdFromChild(id) {
      this.$refs.userModal.show();
      if (id != 0) {
        ApiService.get("auth/" + id).then(({ data }) => {
          this.isCreated = false;
          this.form = data.user;
        });
      } else {
        this.isCreated = true;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.isCreated) {
        ApiService.post("auth", this.form).then(({ data }) => {
          if (data.isSuccess) {
            Swal.fire({
              icon: "success",
              text: "Request is submitted successfully",
              focusConfirm: true
            });
          } else {
            Swal.fire({
              icon: "error",
              text:
                "Request submittion failed, Try again or contact system admin",
              focusConfirm: true
            });
          }
        });
      } else {
        ApiService.post("auth/" + this.form.id, this.form).then(({ data }) => {
          if (data.isSuccess) {
            Swal.fire({
              icon: "success",
              text: "Request is submitted successfully",
              focusConfirm: true
            });
          } else {
            Swal.fire({
              icon: "error",
              text:
                "Request submittion failed, Try again or contact system admin",
              focusConfirm: true
            });
          }
        });
      }
      this.$refs["userModal"].hide();
      this.$parent.callByComponent();
    },
    resetForm() {
      this.form = {
        id: 0,
        account_id: null,
        status: 1,
        full_name: null,
        nic: null,
        email: null,
        permanent_address: null,
        residential_address: null,
        mobile: null,
        phone: null,
        user_name: null,
        referee1: null,
        referee2: null,
        role_id: {
          id: 4
        },
        loans: []
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.p-font,
.form-group {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}
.row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
</style>
