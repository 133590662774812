<template>
  <b-modal id="profile_modal" hide-footer hide-header>
    <div>
      <div class="d-flex justify-content-between align-items-center my-2">
        <h3 class="w-700">Profile</h3>
      </div>
      <hr />
      <div class="p-font">
        <div class="row">
          <div class="col-6 col-md-3 ">Account No:</div>
          <div class="col-6 col-md-6">{{ currentUser.account_id }}</div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 ">Name:</div>
          <div class="col-6 col-md-6">{{ currentUser.full_name }}</div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 ">Permanent Address:</div>
          <div class="col-6 col-md-6">
            {{ currentUser.permanent_address }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 ">Residential Address:</div>
          <div class="col-6 col-md-6">
            {{ currentUser.residential_address }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 ">Mobile:</div>
          <div class="col-6 col-md-6">{{ currentUser.mobile }}</div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 ">Phone:</div>
          <div class="col-6 col-md-6">{{ currentUser.phone }}</div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 ">Registered Date:</div>
          <div class="col-6 col-md-6">{{ currentUser.created_at }}</div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 ">Status:</div>
          <div class="col-6 col-md-6">
            <span class="badge badge-success" v-show="currentUser.status == 1"
              >Active Member</span
            >
            <span class="badge badge-danger" v-show="currentUser.status == 0"
              >Deactive Member</span
            >
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
// import { mapState } from "vuex";
export default {
  data() {
    return {
      currentUser: null
    };
  },
  created() {
    this.currentUser = this.$store.getters.currentUser;
  }
};
</script>
<style lang="scss" scoped>
.p-font {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}
.row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
</style>
