export const SET_USER_ID = 'user_id';

const state = {
  id: null
}

const getters = {
  getUserId (state: { id: any; }) {
    return state.id
  }
}

const mutations = {
  [SET_USER_ID] (state: { id: any; }, payload: any) {
    state.id = payload
  }
}

export default {
  state,
  mutations,
  getters
};
