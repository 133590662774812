<template>
  <div class="no-print">
    <link
      href="https://fonts.googleapis.com/css?family=Montserrat"
      rel="stylesheet"
    />
    <div class="centroassoluto border-top">
      <div class="ftC">
        <div class="orizcontatti">
          <div class="colonnacontatti">
            <a href="mailto:yourname@yourdomain.com">
              <svg
                class="iconacontatti"
                version="1.1"
                id="play"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                height="40px"
                width="40px"
                viewBox="0 0 40 40"
                enable-background="new 0 0 40 40"
                xml:space="preserve"
              >
                <circle
                  class="stroke-under"
                  fill="none"
                  stroke="#808080"
                  cx="20"
                  cy="20.1"
                  r="19.3"
                />
                <path
                  class="stroke-solid"
                  fill="none"
                  stroke="#dcdcdc"
                  d="M39.3,20.1c0,10.7-8.7,19.3-19.3,19.3S0.7,30.7,0.7,20.1"
                />
                <path
                  class="stroke-solid"
                  fill="none"
                  stroke="#dcdcdc"
                  d="M0.7,20.1C0.7,9.4,9.3,0.7,20,0.7s19.3,8.7,19.3,19.3"
                />
                <g class="st0">
                  <g>
                    <path
                      d="M29.2,13.3c-0.5-0.5-1.2-0.8-1.9-0.8H13.1c-1.5,0-2.7,1.2-2.7,2.7v9.7c0,1.5,1.2,2.7,2.7,2.7h14.2
			c1.5,0,2.7-1.2,2.7-2.7v-9.7C29.9,14.4,29.7,13.8,29.2,13.3z M12.2,15.2c0-0.5,0.4-0.9,0.9-0.9h14.2c0.5,0,0.9,0.4,0.9,0.9v9.8
			c0,0.4-0.4,0.8-0.9,0.8H13.1c-0.5,0-0.9-0.4-0.9-0.9V15.2z"
                    />
                    <path
                      d="M17.8,19.9l-4.6,4.3c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0,0.4-0.1l4.7-4.4l1.3,1.1
			c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.3-0.1l1.3-1.2l4.7,4.4c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3-0.1,0.4-0.2
			c0.2-0.2,0.2-0.5,0-0.7l-4.6-4.4l4.6-4.1c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0l-6.3,5.7l-1.2-1.1c0,0,0,0,0,0
			c0,0-0.1-0.1-0.1-0.1l-5-4.5c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.6,0,0.7L17.8,19.9z"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <p>COMPANY MAIL</p>
            <p>
              <a
                href="mailto:support@fastlankacredit.com"
                style="font-weight: normal;"
                >support@fastlankacredit.com</a
              >
            </p>
          </div>

          <div class="vl"></div>
          <div class="ol"></div>

          <div class="colonnacontatti">
            <center>
              <svg
                class="iconacontatti"
                version="1.1"
                id="play"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                height="40px"
                width="40px"
                viewBox="0 0 40 40"
                enable-background="new 0 0 40 40"
                xml:space="preserve"
              >
                <circle
                  class="stroke-under"
                  fill="none"
                  stroke="#808080"
                  cx="20"
                  cy="20.1"
                  r="19.3"
                />
                <path
                  class="stroke-solid"
                  fill="none"
                  stroke="#dcdcdc"
                  d="M39.3,20.1c0,10.7-8.7,19.3-19.3,19.3S0.7,30.7,0.7,20.1"
                />
                <path
                  class="stroke-solid"
                  fill="none"
                  stroke="#dcdcdc"
                  d="M0.7,20.1C0.7,9.4,9.3,0.7,20,0.7s19.3,8.7,19.3,19.3"
                />
                <g class="st0">
                  <g>
                    <path
                      d="M20.3,29.4c5.4,0,9.7-4.3,9.7-9.7c0-5.3-4.4-9.7-9.7-9.7c-5.4,0-9.7,4.3-9.7,9.7c0,1.8,0.5,3.5,1.4,5
			l-1.8,5.2l5.4-1.7C17,29,18.6,29.4,20.3,29.4z M12.1,19.7c0-4.5,3.7-8.1,8.2-8.1c4.5,0,8.2,3.6,8.2,8.1c0,4.5-3.7,8.1-8.2,8.1
			c-1.7,0-3.2-0.5-4.5-1.3l-3.2,1l1-3C12.7,23.2,12.1,21.5,12.1,19.7z"
                    />
                    <path
                      d="M20.6,23.6c2.4,0.9,2.4,0.6,2.9,0.6c0.4,0,1.4-0.6,1.6-1.1c0.2-0.6,0.2-1,0.1-1.1c-0.1-0.1-0.2-0.2-0.5-0.3
			c-0.2-0.1-1.4-0.7-1.6-0.8c-0.2-0.1-0.4-0.1-0.5,0.1c-0.2,0.2-0.6,0.8-0.8,0.9c-0.1,0.2-0.3,0.2-0.5,0.1c-0.2-0.1-1-0.4-1.9-1.2
			c-0.7-0.6-1.2-1.4-1.3-1.6c-0.1-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.2,0-0.3,0-0.4
			c-0.1-0.1-0.5-1.3-0.7-1.8c-0.2-0.5-0.4-0.4-0.5-0.4s-0.3,0-0.5,0c-0.2,0-0.4,0.1-0.6,0.3c-0.2,0.2-0.8,0.8-0.8,2
			c0,1.2,0.9,2.3,1,2.5C16.7,20.2,18.2,22.7,20.6,23.6z"
                    />
                  </g>
                </g>
              </svg>
            </center>
            <p>COMPANY TELEPHONE</p>
            <p>0772679733 / 0717009733</p>
          </div>

          <div class="vl"></div>
          <div class="ol"></div>

          <div class="colonnacontatti">
            <a
              class="iconacontatti"
              href="https://www.google.com/maps/place/Galewela+Bus+Stop/@7.758544,80.5641689,15.25z/data=!4m13!1m7!3m6!1s0x3afcb3c163a202d5:0x711834517baf73d8!2sGalewela!3b1!8m2!3d7.7565045!4d80.5771737!3m4!1s0x3afcb3dc77bafa39:0x3c4dd53ea8179202!8m2!3d7.7598527!4d80.5714989"
              target="_blank"
            >
              <svg
                version="1.1"
                id="play"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                height="40px"
                width="40px"
                viewBox="0 0 40 40"
                enable-background="new 0 0 40 40"
                xml:space="preserve"
              >
                <circle
                  class="stroke-under"
                  fill="none"
                  stroke="#808080"
                  cx="20"
                  cy="20.1"
                  r="19.3"
                />
                <path
                  class="stroke-solid"
                  fill="none"
                  stroke="#dcdcdc"
                  d="M39.3,20.1c0,10.7-8.7,19.3-19.3,19.3S0.7,30.7,0.7,20.1"
                />
                <path
                  class="stroke-solid"
                  fill="none"
                  stroke="#dcdcdc"
                  d="M0.7,20.1C0.7,9.4,9.3,0.7,20,0.7s19.3,8.7,19.3,19.3"
                />
                <g class="st0">
                  <g>
                    <path
                      d="M13.1,26.7l6.9,6.8l6.9-6.8c1.9-1.8,2.9-4.2,2.9-6.8s-1-5-2.9-6.8c-1.9-1.8-4.3-2.8-6.9-2.8
			c-2.6,0-5.1,1-6.9,2.8c-1.9,1.8-2.9,4.2-2.9,6.8C10.2,22.4,11.2,24.9,13.1,26.7z M14.5,14.5c1.5-1.4,3.4-2.2,5.5-2.2
			c2.1,0,4.1,0.8,5.5,2.2c1.5,1.4,2.3,3.4,2.3,5.4s-0.8,3.9-2.3,5.4L20,30.7l-5.5-5.4c-1.5-1.4-2.3-3.4-2.3-5.4
			C12.2,17.8,13,15.9,14.5,14.5z"
                    />
                    <path
                      d="M20,24.4c1,0,2.1-0.4,2.9-1.3c1.6-1.7,1.6-4.5,0-6.2c-0.8-0.9-1.8-1.3-2.9-1.3c-1,0-2.1,0.4-2.9,1.3
			c-1.6,1.7-1.6,4.5,0,6.2C17.9,24,19,24.4,20,24.4z M18,17.8c0.5-0.6,1.3-0.9,2.1-0.9c0.8,0,1.5,0.3,2.1,0.9c1.1,1.2,1.1,3.2,0,4.4
			c-0.5,0.6-1.3,0.9-2.1,0.9c-0.8,0-1.5-0.3-2.1-0.9C16.8,21,16.8,19,18,17.8z"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <p>Fast Lanka Mirco Finance</p>
            <p>Dambulla road, Galewela</p>
          </div>
        </div>

        <p>
          2020 &copy;&nbsp;&nbsp;&nbsp;<a
            href="https://www.linkedin.com/company/crescence/"
            target="_blank"
            >Powered by Crescence Software</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>
<style lang="scss">
.print-only {
  display: none;
}

@media print {
  .no-print {
    display: none;
  }

  .print-only {
    display: block;
  }
}
</style>
