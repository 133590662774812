<template>
  <main>
    <Header></Header>
    <v-app><router-view /></v-app>
    <Profile></Profile>
    <UserForm></UserForm>
    <Footer></Footer>
  </main>
</template>
<script>
import Header from "@/views/layouts/Header";
import Footer from "@/views/layouts/Footer";
import Profile from "@/views/widgets/Profile.Widget";
import UserForm from "@/views/widgets/UserForm.Widget";
export default {
  components: {
    Header,
    Footer,
    Profile,
    UserForm
  }
};
</script>
