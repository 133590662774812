import Vue from "vue";
import axios, { AxiosRequestConfig } from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/jwt.service";
import { API_URL } from "@/services/config";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource: string, params: AxiosRequestConfig | undefined) {
    return Vue.axios.get(resource, params).catch(error => {
      
      if(error.response.status===401){
        JwtService.destroyCurrentUser();
        JwtService.destroyToken();
        window.location.replace("/login");
      }
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource: any, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      if(error.response.status===401){
        JwtService.destroyCurrentUser();
        JwtService.destroyToken();
        window.location.replace("/login");
      }
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource: any, params: any) {
    return Vue.axios.post(`${resource}`, params).catch(error => {
      if(error.response.status===401){
        JwtService.destroyCurrentUser();
        JwtService.destroyToken();
        window.location.replace("/login");
      }
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource: any, slug: any, params: any) {
    return Vue.axios.put(`${resource}/${slug}`, params).catch(error => {
      if(error.response.status===401){
        JwtService.destroyCurrentUser();
        JwtService.destroyToken();
        window.location.replace("/login");
      }
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource: any, params: any) {
    return Vue.axios.put(`${resource}`, params).catch(error => {
      if(error.response.status===401){
        JwtService.destroyCurrentUser();
        JwtService.destroyToken();
        window.location.replace("/login");
      }
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource: string) {
    return Vue.axios.delete(resource).catch(error => {
      if(error.response.status===401){
        JwtService.destroyCurrentUser();
        JwtService.destroyToken();
        window.location.replace("/login");
      }
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
