import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Page from "../views/pages/Base.vue";
import Auth from "../views/auth/Base.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Page",
    component: Page,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        meta: { title: "Home" },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/pages/Home.vue")
      },
      {
        path: "/about",
        name: "About",
        meta: { title: "About Us" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/pages/About.vue")
      },
      {
        path: "/contact",
        name: "Contact",
        meta: { title: "Contact Us" },
        component: () =>
          import(/* webpackChunkName: "contact" */ "../views/pages/Contact.vue")
      },
      {
        path: "/billing/:id",
        name: "Billing",
        meta: { title: "Bill for the Loan Payments" },
        component: () =>
          import(/* webpackChunkName: "billing" */ "../views/pages/Billing.Widget.vue")
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: { title: "Dashboard" },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/pages/Dashboard.vue"
          )
      }
    ]
  },
  {
    path: "/",
    name: "Auth",
    component: Auth,
    redirect: "/login",
    children: [
      {
        path: "/login",
        name: "Login",
        meta: { title: "Login" },
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/auth/Login.vue")
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        meta: { title: "ResetPassword" },
        component: () =>
          import(
            /* webpackChunkName: "reset-password" */ "../views/auth/ResetPassword.vue"
          )
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const DEFAULT_TITLE = "Fast Lanka Micro Credit";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
