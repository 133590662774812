import Vue from "vue";
import Vuex from "vuex";

import breadcrumbs from "./breadcrumbs.module";
import auth from "./auth.module";
import user from "./user.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    breadcrumbs,
    auth,
    user
  }
});
